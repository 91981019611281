import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { ExpandMoreRounded, FiberManualRecord } from "@material-ui/icons";
import {
  Can,
  SourceInfo,
  sourceModulePrivileges,
  sourceModuleTranslation,
  Module,
} from "interfaces";
import { formatLocalizedDate } from "utils";
import { StatusChip } from "./StatusChip";
import { Restricted } from "components";

interface InfoAccordionProps {
  data: SourceInfo[];
}

export const InfoAccordion: React.FC<InfoAccordionProps> = (props) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <>
      {data.map((sourceInfo) => (
        <Restricted
          to={Can.READ}
          at={sourceModulePrivileges[sourceInfo.module]}
          key={"collapsable-" + sourceInfo.module}
        >
          <Accordion defaultExpanded>          
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"panel-" + sourceInfo.module}
              key={"panel-" + sourceInfo.module}
              classes={{ content: classes.accordion }}
            >              
              <Typography variant="body1">
                <strong>{sourceModuleTranslation[sourceInfo.module]}</strong>
              </Typography>
              {sourceInfo.data
                .filter((info) => info.isCritical)
                .every((info) => info.upToDate) ? (
                <StatusChip active={true} />
              ) : (
                <StatusChip active={false} />
              )}
            </AccordionSummary>
            {sourceInfo.data.map((data) => (
              <AccordionDetails key={"panel-detail" + data.source}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  classes={{ root: classes.accordionDetailsGrid }}
                >                  
                  <Grid item>
                    <Grid container alignItems={"center"} spacing={1}>
                      <Grid item>                       
                        <Typography variant="body1">{data.source}</Typography>
                      </Grid>
                    </Grid>
                    <Typography variant={"caption"}>
                      {`Última actualización: ${
                        formatLocalizedDate(
                          data.lastCreation,
                          "dd/MM/yyyy HH:mm"
                        ) ?? "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FiberManualRecord
                      className={
                        data.upToDate ? classes.colorGreen : classes.colorRed
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            ))}
          </Accordion>
        </Restricted>
      ))}
      <Restricted to={Can.READ} at={Module.PERFORMANCE_RATINGS}>
        <Accordion expanded={false}>
          <AccordionSummary
            expandIcon={null}
            aria-controls={"panel-" + Module.PERFORMANCE_RATINGS}
            classes={{ content: clsx(classes.accordion, classes.padding) }}
          >
            <Typography>
              <strong>
                {sourceModuleTranslation[Module.PERFORMANCE_RATINGS]}
              </strong>
            </Typography>
            <StatusChip active={true} />
          </AccordionSummary>
        </Accordion>
      </Restricted>      
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  infIcon: {
    opacity: 0.5,
  },
  accordionDetailsGrid: {
    padding: 5,
  },
  accordion: {
    display: "flex",
    justifyContent: "space-between",
  },
  padding: {
    paddingRight: 32,
  },
  colorRed: {
    color: theme.palette.error.main,
    fontSize: 18,
  },
  colorGreen: {
    color: theme.palette.success.light,
    fontSize: 18,
  },
}));
