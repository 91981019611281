import { Module } from "./module.enum";

export enum SourceInfoOriginType {
  MAGNET = "Magnet",
  DISPATCH = "Dispatch",
  PARKING = "Estacionamiento",
  M6 = "M6",
}

export enum SourceInfoSourceType {
  OPERSTAT = "Operstat",
  QUALIFS = "Calificaciones",
  MAGNET = "Magnet",
  END_OF_SHIFT_PARKING = "Estacionamiento a final de turno",
  SHIFTCHANGE="Cambio de Turno",
}

export interface SourceData {
  source: SourceInfoSourceType;
  upToDate: boolean;
  lastCreation: Date;
  module: string;
  isCritical: boolean;
}

export type SourceInfo = {
  module: InfoSourceModule;
  data: SourceData[];
};

export enum InfoSourceModule {
  TRUCK_ASSIGNMENT = "TRUCK_ASSIGNMENT",
  PERFORMANCE_RATINGS = "PERFORMANCE_RATINGS",
  OPERATOR_ASSIGNMENT = "OPERATOR_ASSIGNMENT",
  LOST_TRUCK = "LOST_TRUCK",
  SHIFT_CHANGE="SHIFT_CHANGE",
}

export const sourceModuleTranslation: Record<InfoSourceModule, string> = {
  [InfoSourceModule.TRUCK_ASSIGNMENT]: "Módulo de Asignación",
  [InfoSourceModule.PERFORMANCE_RATINGS]: "Módulo de Prácticas Operacionales",
  [InfoSourceModule.OPERATOR_ASSIGNMENT]: "Módulo de Armado",
  [InfoSourceModule.LOST_TRUCK]: "Camión Perdido",
  [InfoSourceModule.SHIFT_CHANGE]:"Cambio Turno",
};

export const sourceModulePrivileges: Record<InfoSourceModule, Module> = {
  [InfoSourceModule.TRUCK_ASSIGNMENT]: Module.TRUCK_ASSIGNMENT,
  [InfoSourceModule.PERFORMANCE_RATINGS]: Module.PERFORMANCE_RATINGS,
  [InfoSourceModule.OPERATOR_ASSIGNMENT]: Module.OPERATOR_ASSIGNMENT,
  [InfoSourceModule.LOST_TRUCK]: Module.OPERATOR_ASSIGNMENT,
  [InfoSourceModule.SHIFT_CHANGE]:Module.OPERATOR_ASSIGNMENT,
};
